import { createApi } from "@reduxjs/toolkit/query/react";

import {
    ICreateProjectBody,
    ICreateProjectResponse,
    IDeleteProjectBody,
    IDeleteProjectResponse,
    IGetProjectByIdQuery,
    IGetProjectByIdResponse,
    IGetProjectReviewsQuery,
    IGetProjectReviewsResponse,
    IGetProjectsQuery,
    IGetProjectsResponse,
    ISetProjectManagersBody,
    ISetProjectManagersResponse,
    IUpdateProjectBody,
    IUpdateProjectResponse,
} from "../../types/projects";
import { getProjectsQueryString } from "../../utils/queries";
import { baseQueryWithIntercept } from "../connection/api";

const ENDPOINT_PREFIX = "projects";

export const projectsApi = createApi({
    reducerPath: "projectsApi",
    baseQuery: baseQueryWithIntercept,
    tagTypes: ["Projects", "Project", "Users"],
    endpoints: build => ({
        getProjects: build.query<IGetProjectsResponse, IGetProjectsQuery>({
            query: query => ({
                url: `${ENDPOINT_PREFIX}?${getProjectsQueryString(query)}`,
            }),
            providesTags: (result, _error, _arg) =>
                (result?.data.projects
                    ? [...result.data.projects.map(({ id }) => ({ type: "Projects" as const, id })), "Projects"]
                    : ["Projects"]),
        }),
        createProject: build.mutation<ICreateProjectResponse, ICreateProjectBody>({
            query: data => ({
                url: `${ENDPOINT_PREFIX}/`,
                method: "POST",
                body: data.data,
            }),
            invalidatesTags: ["Projects"],
        }),
        getProjectById: build.query<IGetProjectByIdResponse, IGetProjectByIdQuery>({
            query: data => ({
                url: `${ENDPOINT_PREFIX}/${data.projectId}`,
                method: "GET",
            }),
            providesTags: (result, _error, arg) => [{ type: "Project", id: arg.projectId }],
        }),
        getProjectReviews: build.query<IGetProjectReviewsResponse, IGetProjectReviewsQuery>({
            query: data => ({
                url: `${ENDPOINT_PREFIX}/reviews/${data.projectId}`,
                method: "GET",
            }),
            providesTags: (result, _error, arg) => [{ type: "Project", id: arg.projectId }],
        }),
        updateProject: build.mutation<IUpdateProjectResponse, IUpdateProjectBody>({
            query: data => ({
                url: `${ENDPOINT_PREFIX}/${data.projectId}`,
                method: "PUT",
                body: data.data,
            }),
            invalidatesTags: (_result, _error, arg) => [{ type: "Project", id: arg.projectId }, "Projects"],
        }),
        setProjectManagers: build.mutation<ISetProjectManagersResponse, ISetProjectManagersBody>({
            query: data => ({
                url: `${ENDPOINT_PREFIX}/set-project-managers/${data.projectId}`,
                method: "PATCH",
                body: {
                    managerIds: data.managerIds,
                },
            }),
            invalidatesTags: (_result, _error, arg) => [{ type: "Project", id: arg.projectId }, "Projects", "Users"],
        }),
        deleteProject: build.mutation<IDeleteProjectResponse, IDeleteProjectBody>({
            query: data => ({
                url: `${ENDPOINT_PREFIX}/${data.id}`,
                method: "DELETE",
            }),
            invalidatesTags: (_result, _error, arg) => [{ type: "Project", id: arg.id }, "Projects"],
        }),
    }),
});

export const {
    useGetProjectsQuery,
    useCreateProjectMutation,
    useGetProjectByIdQuery,
    useGetProjectReviewsQuery,
    useUpdateProjectMutation,
    useSetProjectManagersMutation,
    useDeleteProjectMutation,
} = projectsApi;
