import { createApi } from "@reduxjs/toolkit/query/react";

import {
    IAnalyzeProgressBody,
    IAnalyzeProgressResponse,
    IGetClientByIdQuery,
    IGetClientByIdResponse,
    IGetClientModulesReportsQuery,
    IGetClientModulesReportsResponse,
    IGetClientNotificationsQuery,
    IGetClientNotificationsResponse,
    IGetClientsQuery,
    IGetClientsResponse,
    IGetClientUsedTokensQuery,
    IGetClientUsedTokensResponse,
    ISaveProgressReportBody,
    ISaveProgressReportResponse,
    ISendNotificationsToClientBody,
    ISendNotificationsToClientResponse,
    ISetClientPersonnelBody,
    ISetClientPersonnelResponse,
    ISetClientProjectBody,
    ISetClientProjectResponse,
    ISetReadNotificationsBody,
    ISetReadNotificationsResponse,
    IUpdateClientByIdBody,
    IUpdateClientByIdResponse,
} from "../../types/clients";
import { getClientsQueryString } from "../../utils/queries";
import { baseQueryWithIntercept } from "../connection/api";

const ENDPOINT_PREFIX = "clients";

export const clientsApi = createApi({
    reducerPath: "clientsApi",
    baseQuery: baseQueryWithIntercept,
    tagTypes: ["Clients", "Client", "Messages", "UsedTokens", "Project"],
    endpoints: build => ({
        getClients: build.query<IGetClientsResponse, IGetClientsQuery>({
            query: query => ({
                url: `${ENDPOINT_PREFIX}?${getClientsQueryString(query)}`,
            }),
            providesTags: (result, _error, _arg) =>
                (result
                    ? [...result.clients.map(({ id }) => ({ type: "Clients" as const, id })), "Clients"]
                    : ["Clients"]),
        }),
        getClientById: build.query<IGetClientByIdResponse, IGetClientByIdQuery>({
            query: data => ({
                url: `${ENDPOINT_PREFIX}/${data.clientId}`,
                method: "GET",
            }),
            providesTags: (_result, _error, arg) => [{ type: "Client", id: arg.clientId }],
        }),
        getClientNotifications: build.query<IGetClientNotificationsResponse, IGetClientNotificationsQuery>({
            query: data => ({
                url: `${ENDPOINT_PREFIX}/notifications/${data.clientId}`,
                method: "GET",
            }),
            providesTags: (_result, _error, arg) => [{ type: "Messages", id: arg.clientId }],
        }),
        getClientUsedTokens: build.query<IGetClientUsedTokensResponse, IGetClientUsedTokensQuery>({
            query: data => ({
                url: `${ENDPOINT_PREFIX}/used-tokens/${data.clientId}`,
                method: "GET",
            }),
            providesTags: (_result, _error, arg) => [{ type: "UsedTokens", id: arg.clientId }],
        }),
        getClientModulesReports: build.query<IGetClientModulesReportsResponse, IGetClientModulesReportsQuery>({
            query: data => ({
                url: `${ENDPOINT_PREFIX}/modules-reports/${data.clientId}`,
                method: "GET",
            }),
        }),
        updateClientById: build.mutation<IUpdateClientByIdResponse, IUpdateClientByIdBody>({
            query: data => ({
                url: `${ENDPOINT_PREFIX}/${data.clientId}`,
                method: "PUT",
                body: data.data,
            }),
            invalidatesTags: (_result, _error, arg) => [{ type: "Client", id: arg.clientId }, "Clients", { type: "Project", id: arg.projectId }],
        }),
        setClientPersonnel: build.mutation<ISetClientPersonnelResponse, ISetClientPersonnelBody>({
            query: data => ({
                url: `${ENDPOINT_PREFIX}/set-client-personnel/${data.clientId}`,
                method: "PATCH",
                body: {
                    personnelIds: data.personnelIds,
                },
            }),
            invalidatesTags: (_result, _error, arg) => [{ type: "Client", id: arg.clientId }, "Clients"],
        }),
        setClientProject: build.mutation<ISetClientProjectResponse, ISetClientProjectBody>({
            query: data => ({
                url: `${ENDPOINT_PREFIX}/set-project/${data.clientId}`,
                method: "PATCH",
                body: {
                    project: data.projectId,
                },
            }),
            invalidatesTags: (_result, _error, arg) => [{ type: "Client", id: arg.clientId }, "Clients"],
        }),
        sendNotificationsToUsers: build.mutation<ISendNotificationsToClientResponse, ISendNotificationsToClientBody>({
            query: data => ({
                url: `${ENDPOINT_PREFIX}/send-notification`,
                method: "POST",
                body: data,
            }),
            invalidatesTags: (_result, _error, arg) => [{ type: "Messages", id: arg.clientId }, "Messages"],
        }),
        setReadNotifications: build.mutation<ISetReadNotificationsResponse, ISetReadNotificationsBody>({
            query: data => ({
                url: `${ENDPOINT_PREFIX}/set-read-notification`,
                method: "POST",
                body: data,
            }),
            invalidatesTags: (_result, _error, arg) => [{ type: "Messages", id: arg.clientId }, "Messages", "Clients"],
        }),
        analyzeProgress: build.mutation<IAnalyzeProgressResponse, IAnalyzeProgressBody>({
            query: data => ({
                url: `${ENDPOINT_PREFIX}/analyze-progress`,
                method: "POST",
                body: data,
            }),
            invalidatesTags: [],
        }),
        saveProgressReport: build.mutation<ISaveProgressReportResponse, ISaveProgressReportBody>({
            query: data => ({
                url: `${ENDPOINT_PREFIX}/save-progress-report`,
                method: "POST",
                body: data,
            }),
            invalidatesTags: (_result, _error, arg) => [{ type: "Client", id: arg.clientId }, "Clients"],
        }),
    }),
});

export const {
    useGetClientsQuery,
    useGetClientByIdQuery,
    useGetClientNotificationsQuery,
    useGetClientUsedTokensQuery,
    useGetClientModulesReportsQuery,
    useAnalyzeProgressMutation,
    useUpdateClientByIdMutation,
    useSetClientPersonnelMutation,
    useSetClientProjectMutation,
    useSendNotificationsToUsersMutation,
    useSetReadNotificationsMutation,
    useSaveProgressReportMutation,
} = clientsApi;
